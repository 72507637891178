.form-signin {
  /*max-width: 330px;*/
  /*padding: 15px;*/
  /*margin: 0 auto;*/
}
.form-signin .form-signin-heading {
  margin-bottom: 10px;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

html, body, #content {
  height: 100%;
  width: 100%;
}


#content {
  background-color: #fff;

  -moz-box-shadow: 0 0 20px #888;
  -webkit-box-shadow: 0 0 20px #888;
  box-shadow: 0 0 20px #888;
}

.scrollable{
    overflow: auto;
    -webkit-transition-property: top, bottom;
    transition-property: top, bottom;
    -webkit-transition-duration: .2s, .2s;
    transition-duration: .2s, .2s;
    -webkit-transition-timing-function: linear, linear;
    transition-timing-function: linear, linear;
    -webkit-overflow-scrolling: touch;
}

#grid1 {
  /*height: 565px;*/
  /*min-width: 1140px;*/
  width: 100%;
}

#grid2 {
  /*height: 565px;*/
  /*min-width: 1140px;*/
  width: 100%;
}

.ui-grid-header-cell {float: left;}
.ui-grid-header-cell { min-height: 15px; }

.angular-google-map-container { height: 600px; }

input.ng-invalid.ng-dirty {
    border-color: red;
}

.validation-message {
  color: red;
}

img.poi-image {
  max-height: 200px;
  margin: auto;
}

.drop-box {
  border: 2px dashed #CCC;
  vertical-align: middle;
  margin: auto;
  text-align: center;
  height: 200px;
  padding-top: 90px;
  color: #CCC;
  font-weight: bold;
  width: 100%;
}

.preview {
  width: 100%;
  margin: auto;
  max-height: 200px;
}

.preview img {
  max-height: 200px;
}

/*	start styles for the ContextMenu	*/
.context_menu{
	background-color:white;
	border:1px solid gray;
}
.context_menu_item{
	padding:3px 6px;
}
.context_menu_item:hover{
	background-color:#CCCCCC;
}
.context_menu_separator{
	background-color:gray;
	height:1px;
	margin:0;
	padding:0;
}
/*	end styles for the ContextMenu	*/

.title {
  font-size: 25px;
  margin-left: 35%;
}

.loading {
  font-size: 14px;
}

.poilist {
  max-height: 700px;
  /*overflow: hidden;*/
  margin-left: -15px;
  margin-right: -15px;
}

.list-container {
  margin-left: 15px;
  margin-right: 15px;
}

#map-canvas {
  top: 130px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
}

.image-container {
  margin-bottom: 10px;
}

.navbar-default .navbar-nav>li.primary-item>a{
  color: #337ab7;
  cursor: pointer;
}
.navbar-nav>li>a {
    cursor: pointer;
}

.alert-info {
  padding: 0;
}

.ui-grid-pager-control input {
  width: 60px;
}

.ui-grid-row-dirty .ui-grid-cell {
  color: #2c3e50;
}

@media (min-width: 768px) {
  .navbar-nav>li>a {
      padding-top: 23px;
      padding-bottom: 15px;
  }
}

.navbar-default .navbar-nav>li.primary-item>a.btn.btn-info.bottone {
  padding-top: 7px;
  margin-top: 15px;
  padding-bottom: 6px;
  color: white;
}
.navbar-default .navbar-nav>li.primary-item>a.btn.btn-info.bottone:hover {
  background-color: #4a99b1;
  color: white;
}
